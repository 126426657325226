import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/components/layout/index.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Tabs = makeShortcode("Tabs");
const Tab = makeShortcode("Tab");
const TokensLookup = makeShortcode("TokensLookup");
const FeedbackThumbs = makeShortcode("FeedbackThumbs");
const GetInTouch = makeShortcode("GetInTouch");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Tabs mdxType="Tabs">
  <Tab title="Global" mdxType="Tab">
    <TokensLookup type="time" tagline="Transition tokens determine how quickly different elements animate in and out." brand="Global" removeBottomBorder mdxType="TokensLookup" />
  </Tab>
  <Tab title="Sainsbury's" mdxType="Tab">
   <TokensLookup type="time" tagline="Transition tokens determine how quickly different elements animate in and out." brand="Sainsburys" removeBottomBorder mdxType="TokensLookup" />
  </Tab>
  <Tab title="Argos" mdxType="Tab">
   <TokensLookup type="time" tagline="Transition tokens determine how quickly different elements animate in and out." brand="Argos" removeBottomBorder mdxType="TokensLookup" />
  </Tab>
  <Tab title="Tu" mdxType="Tab">
   <TokensLookup type="time" tagline="Transition tokens determine how quickly different elements animate in and out." brand="Tu" removeBottomBorder mdxType="TokensLookup" />
  </Tab>
  <Tab title="Habitat" mdxType="Tab">
   <TokensLookup type="time" tagline="Transition tokens determine how quickly different elements animate in and out." brand="Habitat" removeBottomBorder mdxType="TokensLookup" />
  </Tab>
  <Tab title="Nectar" mdxType="Tab">
    
   <TokensLookup type="time" tagline="Transition tokens determine how quickly different elements animate in and out." brand="Nectar" removeBottomBorder mdxType="TokensLookup" />
  </Tab>
    </Tabs>
    <hr></hr>
    <FeedbackThumbs mdxType="FeedbackThumbs" />
    <hr></hr>
    <GetInTouch mdxType="GetInTouch">
  We’re on hand to answer any questions you have or help you with your next
  project.
    </GetInTouch>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      